.App {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  background-color: #f8f8f8;
}

.Page {
  &Container {
    display: flex;
    flex-direction: column;
    width: 100dvw;
    height: 100dvh;
  }

  &Title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 3px solid #ada9a9;
    margin-bottom: 10px 20px;
    //height: 7.5dvh;

    > h1 {
      margin: 0 0 0 15px;
    }
  }

  &Content {
    height: 90%;
    padding: 15px;
    box-sizing: border-box;
    width: 80dvw;
  }
}

button {
  padding: 20px;
  font-size: 14px;
  //background-color: #0B3F3C !important;
  //text-transform: capitalize !important;
}

@media (max-width: 480px) {
  .Page {
    &Container {
      width: 100%;
    }

    &Title{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      border-bottom: 3px solid #cecece;

      > h1 {
        margin: 0 0 0 5px;
      }
    }

    &Content {
      width: 100%;
      padding: 5px;
    }
  }
}

::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}
.ProgressBarContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;

  .ProgressBarTotal {
    background-color: #D9D9D9;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 50%;
    height: 20px;
    border-radius: 10px;
    cursor: default;
    overflow: hidden;


    .ProgressBarFill {
      border-radius: 10px;
      height: 100%;
      background-color: #00A32E;
      transition: width 500ms ease-in-out;
    }
  }

  .ProgressBarLabel {
    margin-left: 5px;
  }
}
.SendProduction {
  &Container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
    column-gap: 50px;
    padding: 5px;
  }

  &Form {
    padding: 15px;
    height: 90%;
    background-color: #de1f1f;
    border-radius: 10px;

    &Inputs {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      row-gap: 20px;
      flex-direction: column;
      width: 30%;
    }

    &Input {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    &Button {
      width: 100%;
      height: 50px;
      background-color: #0B3F3C !important;
    }
  }

  &List {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 97%;
    border: 1px solid gray;
    height: 80%;
    background-color: white;
    padding: 5px;
    border-radius: 15px;
    flex: 2;

    &Title {
      display: flex;
      justify-content: center;
      margin: 0;
    }

    &Items {
      display: flex;
      flex-direction: column;
      //justify-content: flex-end;
      align-items: center;
      //height: 90%;
      overflow-y: auto;
      width: 100%;
    }
  }
}

@media (max-width: 480px) {
  .SendProduction {
    &Container {
      flex-direction: column;
      row-gap: 15px;
      width: 97.5%;
    }

    &Form{
      &Inputs {
        flex-direction: column;
        row-gap: 10px;
        box-sizing: border-box;
        width: 100%;
      }

      &Input {
        width: 100%;
      }

      &Button {
        width: 100%;
      }
    }

    &List {
      height: 40%;
      overflow-y: hidden;
    }
  }
}

.EmptyFlag {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  row-gap: 50px;
}
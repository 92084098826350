.Settings {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  width: 100%;

  &Section {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 98%;
    padding: 0 10px 0 10px;
    row-gap: 15px;

    &:first-child {
      border-right: 1px solid gray;
    }

    //&.Billing {
    //  width: 30%;
    //}

    .ProfilePicture {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: flex-start;
      width: 100%;

      &Picture {
        width: 100px;
        height: 100px;
      }

      &Options {
        display: flex;
        justify-content: space-between;
        width: 75%;
      }
    }
  }

  .Plan {
    background-color: #d3d3d3;
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .Name {
      // font-size: 12px;
    }

    .Price {
    }
 }

 .Payment {
    width: 100%;
      &Method {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
      }

      &Option {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 10px;
        align-items: center;
        height: 50px;
        width: 50px;
        background-color: #2E3F3E;
        border-radius: 5px;
        color: white;
        font-size: 12px;
        cursor: pointer;

        &.Selected {
          background-color: red;
        }
      }
    }

}

.ChangePassword {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 15px;
  width: 100%;

  &Field {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 5px;
    width: 80%;

    label {
      font-weight: 500;
      font-size: 18px;
    }
  }
}

.ProductionDetails {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 15px;
  height: 100%;

  &Title {
    margin: 0;
  }

  &Content {
    max-height: 90%;
  }

  &Filters {
    display: flex;
    flex-direction: row;

    &.Column {
      width: 100%;
      align-items: center;
      flex-direction: column;
      row-gap: 15px;
    }
  }
}

@media (max-width: 480px) {
  .ProductionDetails {
    width: 97%;
  }
}

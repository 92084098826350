.SidebarHeader {
  &Container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    color: white;
    padding: 20px;

    > div {
      width: 100%;
      overflow: hidden;
    }
  }

  &Avatar {
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    color: white;
    font-size: 24px;
    font-weight: 700;

    &.small {
      width: 40px;
      height: 40px;
    }

    transition: height 0.2s ease-in-out, width 0.2s ease-in-out;
  }
}
#IndexNav {
  .Full {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    height: 10dvh;
    overflow: hidden;
    background-color: #3F1416;
    z-index: 5;

    img {
      width: 200px;
    }

    .Links {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      text-transform: uppercase;
    }

    a {
      color: white;
      text-decoration: none;
      width: auto;

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
    
    .Outlined {
      background-color: #0B3F3C;
      padding: 20px;
      border-radius: 5px;
    }

    .Icon {
      display: none;
      margin-right: 20px;
    }

    :nth-last-child(2) {
      display: none;
    }
  }

  .Minimized {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 0;
    background-color: #3F1416;
    row-gap: 25px;
    text-transform: uppercase;
    transition: height ease-in-out 150ms;

    a {
      display: none;
    }

    &.Expanded {
      height: 30dvh;
      padding: 25px;
      transition: height ease-in-out 150ms;

      a {
        display: block;
        color: white;
        text-decoration: none;
        text-align: center;

        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }

      .Outlined {
        width: 75%;
        text-align: center;
        background-color: #0B3F3C;
        padding: 20px;
        border-radius: 5px;
        color: white;
      }
    }
  }
}

@media (max-width: 840px) {
  #IndexNav {
    .Full {

      img {
        width: 150px;
      }

      .Links {
        display: none;
      }

      .Icon {
        display: block;
      }

      :nth-last-child(2) {
        display: block;
      }
    }
  }
}
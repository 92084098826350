.SectorMembers {
  width: 100%;
  height: 250px;
  margin-bottom: 50px;

  .SearchContainer {
    width: 100%;
  }

  .SelectedMembers {
    .Item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px;
      background-color: lightgray;
      margin-top: 5px;
      border-radius: 2px;
      font-weight: bold;
    }
  }
}

.ButtonsContainer {
  display: flex;
  flex-direction: row;
  justify-self: flex-end;
}
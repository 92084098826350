.SidebarFooter {
  &Container {
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    width: 80%;
    padding: 10px;
  }

  &Item {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 20px;
    height: 40px;
    border-radius: 6.25px;
    cursor: pointer;
    background-color: #3F2527;
    width: 100%;

    &Small {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      border-radius: 6.25px;
      cursor: pointer;
      background-color: #3F2527;
      width: 20%;
    }
  }

  >span {
    margin-left: 15px;
  }

  &:hover {
    background-color: #253F3D;
  }
}
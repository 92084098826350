form {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

form > input[type=submit] {
  background-color: #233771;
  color: white;
  border: none;
  height: 30px;
  width: 300px;
  border-radius: 5px;
}

.FormField {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.FormField > label {
  font-weight: bold;
}

.FormField > input {
  border: none;
  border-bottom: 1px solid #233771;
  border-radius: 5px;
  height: 30px;
  width: 300px;
}

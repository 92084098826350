.Sector {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;

  &Title {
    height: 10%;
    font-size: 18px;
    font-weight: bold;
  }

  &Container {
    display: flex;
    flex-direction: column;
    height: 90%;
    width: 100%;
  }

  &Content {
    width: 100%;
    height: 100%;
    padding: 10px;
    box-sizing: border-box;
    background-color: white;
    border-radius: 0 10px 10px 10px;
    border: 1px solid #f0f0f0;
  }
}


.Mui-selected {
  background-color: white !important;
  color: black !important;
  //border: none !important;
  border-top: 2px solid lightblue !important;
}


.MuiTabs-indicator {
  display: none;
}
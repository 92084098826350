.ButtonComponent {
  border: none;
  background-color: #0B3F3C;
  border-radius: 5px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;

  &:hover {
    cursor: pointer;
    background-color: #2c5552;
    transition: background-color 150ms ease;
  }
}

.CreateLead {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  row-gap: 15px;

  .Fields {
    width: 70%;
    display: flex;
    flex-direction: column;
    row-gap: 5px;

    .Products {
      .Area {
        min-height: 150px;
        width: 100%;
        background-color: #d3d3d3;
        border-radius: 5px;

        .Item {
          width: 100%;
          height: 50px;
          background-color: lightgrey;
        }
      }
    }
  }
}

.Leads {
  width: 100%;
  height: 100%;
  .Title {
    margin: 0;
  }
  .Content {
    width: 95%;
    height: 90%;
  }

  .Filters {
    display: flex;
    flex-direction: row;
    column-gap: 15px;
  }
}

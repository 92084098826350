.SidebarContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: visible;
}

.ArrowContainer {
  position: absolute;
  display: flex;
  justify-content: center;
  align-self: flex-end;
  top: 50px;
  z-index: 99999;
  background-color: #253F3D;
  border-radius: 50%;
  align-items: center;
  width: 40px;
  height: 40px;

  transition: left 150ms linear;
}

.SidebarBackdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100dvw;
  height: 100dvh;
}
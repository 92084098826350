.Dashboard {
  &Container {
    display: flex;
    justify-content: center;
    height: 100%;
    border-radius: 10px;

    .Today {
      width: 50%;
    }

    .Goals {
      width: 50%;
    }

    .Section {
      width: 100%;
      height: 100%;
      padding: 10px;

      .LeftSection {
        height: 50%;
      }

      .SectionTitle {
        font-size: 25px;
        font-weight: bold;
        text-transform: uppercase;
        color: black;
        display: flex;
        align-items: center;
        height: 30px;
      }
      .Container {
        gap: 25px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        height: 80%;
        overflow-y: auto;

        .Column {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 270px;
        }


        .Row {
          background-color: white;
          box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.322);

          &Content {

            .Titles {

              .ProductName {
                width: 20%;
              }

              .ProductAmounts {
                display: flex;
                justify-content: space-between;
                width: 80%;
                overflow: hidden;
              }

              span {
                display: flex;
                flex-direction: column;
              }
            }
          }
        }

        .Card {
          display: flex;
          flex-direction: column;
          height: 125px;
          width: 198px;
          box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.089);
          border-radius: 10px;
          color: black;
          }

        .Name {
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          font-weight: normal;
          border-radius: 10px 10px 0 0;
          padding: 10px;
          background-color: #3f0b0e;
          width: calc(100% - 20px);
          color: white;
          height: 30%;
          font-size: 18px;
          text-transform: uppercase;
        }

        .Value {
          display: flex;
          justify-content: left;
          align-items: flex-end;
          height: 70%;
          padding: 10px;
          font-size: 1.5em;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .Dashboard {
    &Container {
      flex-direction: column;
      justify-content: flex-start;
      row-gap: 50px;
      overflow-y: auto;
      width: 95%;

      .Section {
        width: 100%;
        max-height: 60%;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;

        .SectionTitle {
          display: flex;
          width: 90%;
          font-size: 20px;
          height: 80px;
        }

        .Container {
          display: flex;
          justify-content: space-between;
          height: 90%;
          min-width: 100%;
          flex-wrap: nowrap;
          overflow-x: auto;


          &.Vertical {
            flex-direction: column;
          }

          .Column {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 80%;
            width: 50%;
            row-gap: 10px;
            .Card {
              width: 100px;
              height: 80px;

              .Name {
                font-size: 12px;
              }

              .Value {
                font-size: 18px;
              }
            }
          }
        }
      }
    }
  }
}
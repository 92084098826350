.Dropdown {
  &Container {
    width: 150px;
    height: calc(100% - 20px);
  }

  &Label {
    height: 20px;
    font-size: 14px;
    text-align: left;
    margin-left: 5px;
  }

  &Selector {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    height: 60%;
    padding: 10px;
    cursor: pointer;
    background-color: transparent;
    color: black;
    top: 0;

    &.Compact {
      padding: 0 5px;
      height: 30px;
    }
  }

  &Options {
    position: absolute;
    max-height: 150px;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: white;
    box-shadow: 0 0 12px 0 rgba(0,0,0,0.16);
    border-radius: 5px;
    display: none;
    margin: 0;
    padding: 5px 0;
    max-width: 150px;
    color: black;
    font-size: 12px;
    text-align: left;

    &.Expanded {
      display: flex;
      flex-direction: column;
      list-style-type: none;
      z-index: 2;
      width: 60%;

      &.Compact {
        width: 125%;
      }
    }
  }

  &Option {
    max-height: none;
    display: flex;
    align-items: center;
    min-height: 35px;
    cursor: pointer;
    padding: 0 10px;
    width: 92%;

    &:hover {
      background-color: #f0f0f0;
    }
  }
}

.HistoryTableItem {
  border: 1px solid lightgray;
  box-shadow: none !important;
  border-radius: 0 !important;
  padding: 0 10px !important;

  span {
    padding: 0 10px;
    display: flex;
    align-items: center;
    border-right: 1px solid lightgray;
    height: 100%;
  }
}

// Alert.scss

.alert {
  left: calc(25%);
  position: absolute;
  top: 2%;
  z-index: 5;
  width: 48%;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-radius: 5px;
  margin-bottom: 10px;
  color: white;
  font-weight: bold;
  opacity: 0; // Start with opacity 0 to fade in
  transform: translateY(-100%); // Start above the screen
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out; // Transition effect

  .icon {
    margin-right: 10px;
  }

  .message {
    flex: 1;
  }

  &.info {
    background-color: #3498db; // Blue color for info alerts
  }

  &.error {
    background-color: #e74c3c; // Red color for error alerts
  }

  &.alert {
    background-color: #f39c12; // Orange color for alert alerts
  }

  &.success {
    background-color: #00A32E;
  }

  &.show {
    opacity: 1; // Fade in effect
    transform: translateY(0); // Slide down animation
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out; // Transition effect
  }
}

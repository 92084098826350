.Background {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100dvh;
    width: 100dvw;

    .BackgroundCover {
        z-index: 2;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.5);
        width: 100%;
        height: 100%;
    }

    .BackgroundImage {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
}

.FormContainer {
    z-index: 3;
    background-color: #3f0b0e;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    color: white;
    width: 40%;
    height: 85%;
    border-radius: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

    .LogoContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-size: 20px;

        img{
            width: 30%;
        }

        h4 {
            font-size: 18px;
            magin-top: 0;
            margin-bottom: 20px;
            text-align: center;
        }
    }

    .InputsContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        box-sizing: border-box;
        padding: 10px;
        align-items: center;
        width: 95%;
        height: 30%;
        margin-top: 10px;
        border-radius: 15px;

        label {
            color: white;
            margin-bottom: 5px;
        }

        .FieldContainer {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
        }

        .reminder {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            padding: 10px;
        }
    }

    .ForgotPassword {
        font-size: 15px;
        margin: 15px;
        cursor: pointer;

        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }

    .ButtonsContainer {
        width: 80%;
        height: 10%;
        padding-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        .button-login {
            width: 80%;
            height: 70%;
            background-color: #363F3E;
            color: white;
            border-radius: 15px;

            &:hover {
                background-color: #0B3F3C;
                transition: 5ms;
            }
        }
    }
}


@media (max-width: 480px) {
    .Background {
        .BackgroundImage {
            width: auto;
        }
    }

    .FormContainer {
        width: 95%;

        .LogoContainer {

            h4 {
                text-align: center;
            }
        }
    }
}

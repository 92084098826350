.History {
  &Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
  }

  &Filters {
    display: flex;
    align-items: center;
    column-gap: 15px;
    max-height: 50px;
    overflow: visible;
  }

  &Body {
    height: 78dvh;
    width: 96%;
    overflow: hidden;
    margin-bottom: 25px;
  }
}

.ProductionReport {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  row-gap: 15px;


  .Section {
    width: 100%;

    .FileType {
      width: 100%;
      display: flex;
      flex-direction: column;

      &.Title {
        margin: 8px 0;
        text-align: left;
        font-size: 14px;
      }

      &.Content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
      }

      &.Option {
        width: 50px;
        background-color: #0B3F3C;
        color: white;
        padding: 10px;
        border-radius: 10px;

      }
    }
  }
}

@media (max-width: 480px) {
  .History {
    &Header {
      margin-bottom: 25px;
      width: 95%;
    }

    &Body {
      //height: 90%;
    }
  }
}
.Container {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 25px;
  height: 85%;

  .Header{
    display: flex;
    justify-content: flex-start;
    background-color: #f0f0f0;
    width: 100%;
    padding: 10px 20px 10px 20px;
    color: black;
    font-size: 16px;
    border-radius: 10px;

    :nth-child(1), :nth-child(2), :nth-child(3), :nth-child(4) {
      width: 13.8%;
    }
  }

  .RowContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    //height: 100%;
    overflow-y: auto;
    .Row {
      width: 95%;
      margin-top: 10px;
      border-radius: 10px;
      height: auto;
      background-color: #f0f0f0;
      display: flex;
      flex-direction: column;
      color: black;
      padding: 5px;
      margin: 10px;

      &Content {
        padding: 10px;
        list-style-type: none;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 50px;

        .Progress {
          width: 35%;
        }

        .Icon {
          display: flex;
          justify-content: center;
          width: 10%;
        }

        .ProductName {
          width: 20%;
          font-weight: bold;
        }

        .ProductContent{
          display: flex;
          justify-content: space-between;
          width: 80%;
          font-weight: bold;

          .ProductAmounts {
            display: flex;
            width: 65%;
            justify-content: space-between;
            .ProductAmount {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
            }
          }
        }

        :last-child {
          cursor: pointer;
        }

        .Titles {
          display: flex;
          justify-content: flex-start;
          background-color: #f0f0f0;
          width: 100%;
          color: black;
          font-size: 16px;
          border-radius: 10px;

          :nth-child(1), :nth-child(2), :nth-child(3), :nth-child(4) {
            width: 20%;
          }
        }
      }

      &Collapse {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 0;
        overflow: hidden;
        transition: height 200ms ease-in;

        &.open {
          margin: 10px;
          height: 150px;
          overflow-y: scroll;
          transition: height 200ms ease-in;
        }

        &Item {
          display: flex;
          align-items: center;
          width: 100%;
          height: 50px;
          border-bottom: 1px solid #aaaaaa;

          > *{
            width: 15%;
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .Container {
    width: 95%;
    margin: 6px;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;

    .Header {
      width: 93%;
      margin-bottom: 10px;

      :nth-child(1), :nth-child(2), :nth-child(3), :nth-child(4) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 25%;
      }
    }

    .RowContainer {
      .Row {
        scroll-snap-align: center;

        &Content {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .ProductName {
            width: 100%;
            display: flex;
            justify-content: center;
            height: 20%;
          }

          .ProductContent {
            display: flex;
            flex-direction: column;
            font-weight: normal;
            height: 80%;
            width: 100%;

            .ProductAmounts {
              display: flex;
              flex-direction: column;
              width: 100%;
              height: 80%;
              .ProductAmount {
                display: flex;
                flex-direction: row;
                width: 100%;
                height: 20%;
                padding: 5px 0;
              }
            }

            .Progress {
              width: 100%;

              .ProgressBarContainer {
                .ProgressBarTotal {
                  width: 80%;
                }
              }
            }
          }
        }
      }
    }
  }
}

.MembersTables {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 100px;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &Section {
    height: 40%;
  }
}
#Header {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100dvw;
    height: 50px;
    background-color: white;
}

.HeaderContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.SideMenuToggle {
    display: flex;
    justify-content: center;
    width: 50px;
    cursor: pointer;
}

.HeaderSideMenu {
    transition: width 300ms;
}

.HeaderActionButton {
    max-height: 30px;
    max-width: 80px !important;
    font-size: 10px !important;
    background-color: #0B3F3C !important;
}

.PageTitle {
    font-size: x-small;
}
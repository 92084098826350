body{
    scroll-behavior: smooth !important;
    font-family: sans-serif;
    font-size: 100%;
}

.SectionTitle {
    display: flex;
    justify-content: space-between;
    font-weight: normal;
    font-size: 15px;
    //margin-bottom: 50px;
    text-transform: uppercase;
}

#root {
    scroll-snap-type: y mandatory;
}

section {
    scroll-snap-align: start;
}

#Hero {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: 70dvh;
    background-color: #3F1416;
    padding: 20px;

    .Left {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        font-size: 2.5em;
        color: white;
        width: 48%;
        height: 25%;

        p {
            width: 90%;
        }
    }

    .Right {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 48%;
        height: 80%;

        img {
            width: 90%;
        }
    }
}

#Features {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px 0;
    background-color: #f0f0f0;

    .OptimusPrime {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        gap: 50px;

        .Item {
            display: flex;
            flex-direction: row-reverse;
            gap: 50px;
            width: 80%;

            img {
                width: 50%;
                border-radius: 5px;
            }

            .TextContainer {
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 25%;
                text-transform: uppercase;
                font-size: 18px;

                :nth-child(1) {
                    font-weight: lighter;
                    font-size: 25px;
                    width: 40%;
                }
            }

            &.Reverse {
                display: flex;
                justify-content: center;
                background-color: #3F1416;
                color: white;
                width: 100%;
            }

            &.Content {
                display: flex;
                flex-direction: row-reverse;
                width: 80%;
                padding: 25px;
            }
        }

        :nth-child(odd) {
            flex-direction: row;
        }
    }
}

#Plans {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 80dvh;
    background-color: #3F1416;

    .SectionTitle {
        color: white;
        font-size: 1.5em;
    }

    .PlanTablesContainer {
        display: flex;
        justify-content: space-around;
        height: 80%;
        width: 90%;

        .PlanTableContainer {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            color: white;
            height: 100%;
            width: 300px;
            text-align: center;
            text-transform: uppercase;
            font-size: 15px;

            .PlanTable {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                height: 80%;
                width: 100%;
                background-color: white;
                border-radius: 10px;
                color: black;

                .Title {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 20%;
                    text-transform: capitalize;
                    font-size: 22px;
                    font-weight: bold;
                }

                .Price {
                    width: 80%;
                    border-top: 1px solid rgba(0, 0, 0, 0.2);
                    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 20%;

                    .Box {
                        width: 100%;
                        height: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        :nth-child(1) {
                            color: rgba(0, 0, 0, 0.7);
                            display: flex;
                            justify-content: flex-end;
                            font-size: 18px;
                            align-self: flex-start;
                            width: 25%;
                        }
                        
                        :nth-child(2) {
                            font-size: 48px;
                            width: 50%;
                            margin: 0 5px;
                        }

                        :nth-child(3) {
                            color: rgba(0, 0, 0, 0.7);
                            align-self: flex-end;
                            font-size: 18px;
                            width: 25%;
                            display: flex;
                            justify-content: flex-start;
                        }
                    }
                }

                .Benefits {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 30%;

                    ul > li {
                        list-style-type: "✅";
                    }
                }

                .Button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 20%;

                    button {
                        border: none;
                        background-color: #0B3F3C;
                        padding: 20px;
                        color: white;
                        text-transform: uppercase;
                        border-radius: 5px;
                    }
                }
            }
        }
    }
}

#Contact {
    height: 40dvh;

    .TitleContainer {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        width: 100%;
        height: 20%;

        .SectionTitle {
            margin: 0;
            padding-bottom: 20px;
        }
    }

    .ContactFormContainer {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        height: 100%;
        background-color: #3F1416;
        padding: 25px 0;

        form {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            height: 80%;
            width: 80%;

            input {
                border: none;
                border-radius: 10px;
                height: 40px;
                padding: 10px;

                &:focus {
                    outline: none;
                }
            }

            textarea {
                height: 100%;
                width: 100%;
                border: none;
                border-radius: 10px;
                padding: 10px;
                box-sizing: border-box;
            }

            .InputFields {
                width: 100%;
                display: flex;
                height: 70%;
                gap: 25px;

                .LeftSection {
                    height: 100%;
                    width: 50%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                }

                .RightSection {
                    height: 100%;
                    width: 50%;

                    input {
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            .Submit {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 20%;
                width: 100%;

                input {
                    width: 75%;
                    background-color: #0B3F3C;
                    border: none;
                    color: white;
                    font-size: 16px;
                    text-transform: uppercase;
                    cursor: pointer;
                }
            }
        }

        .Footer {
            display: flex;
            align-items: flex-end;
            height: 10%;
            color: white;

            p {
                margin-block-end: 0;
            }
        }
    }
}

@media (max-width: 480px) {
    #Hero {
        flex-direction: column;
        align-items: center;
        width: 100dvw;
        padding: 0;
        text-align: center;

        .Left {
            width: 100%;
            height: 50%;
        }

        .Right {
            width: 100%;
            height: 50%;
        }
    }

    #Features {
        .SectionTitle {
            text-align: center;
            width: 80%;
        }

        .OptimusPrime {
            .Item {
                flex-direction: column-reverse;
                justify-content: center;
                align-items: center;

                &.Content {
                    align-items: center;
                    justify-content: center;
                    flex-direction: column-reverse;
                }

                &.Reverse {
                    align-items: center;
                }

                img {
                    width: 100%;
                }

                .TextContainer {
                    :first-child {
                        width: 100%;
                        text-align: center;
                    }
                }
            }

        }
    }

    #Plans {
        .SectionTitle {
            margin-top: 50px;
            margin-bottom: 0;
        }

        .PlanTablesContainer {
            .PlanTableContainer {
                justify-content: center;
            }
        }
    }

    #Contact {
        height: 60dvh;

        .TitleContainer {
            display: flex;
            justify-content: center;
            text-align: center;
        }

        .ContactFormContainer {

            form {
                height: 100%;

                .InputFields {
                    flex-direction: column;

                    .LeftSection {
                        row-gap: 20px;
                        width: 100%;
                    }

                    .RightSection {
                        width: 100%;
                    }
                }

                textarea {
                    height: 125px;
                }
            }
        }
    }
}

@media (max-width: 360px) {

    #Features {
        .SectionTitle {
            width: 90%;
        }
    }

    #Contact {
        .TitleContainer {
            padding-top: 20px;
        }

        .ContactFormContainer {

            form {
                height: 100%;

                textarea {
                    height: 75px;
                }
            }
        }
    }
}
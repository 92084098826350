.Checkout {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100dvw;
  height: 100dvh;
  overflow: hidden;

  &Background {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  &Container {
    z-index: 2;
    display: flex;
    align-self: center;
    align-items: center;
    flex-direction: column;
    width: 50%;
    height: 80%;
    background-color: #3F1416;
    color: white;
    border-radius: 15px;

    &.Errors {
      position: absolute;
      z-index: 2;
      left: 80%;
      width: 15%;
      height: 90%;
      background-color: #3F1416;
      display: flex;
      align-self: center;
      align-items: center;
      flex-direction: column;
      color: white;
      border-radius: 15px;
    }
  }

  &Header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 10%;
    width: 90%;
    font-size: 30px;
  }

  &Body {
    display: flex;
    width: 90%;
    height: 80%;
    justify-content: space-between;
    column-gap: 5%;

    &Section {
      height: 100%;
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
      flex-direction: column;

      &Title {
        height: 5%;
      }

      &Input {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        row-gap: 20px;

        &Field {
          display: flex;
          flex-direction: column;
          row-gap: 2px;
          height: 100px;

          .Password {
            &Container {
              display: flex;
              input {
                width: 80%;
              }
            }

            &Toggle {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 100%;
              width: 20%;
            }
          }

          input {
            border: none;
            border-radius: 10px;
            height: 50px;
            padding: 10px;

            &:focus {
              outline: none;
            }
          }

          &.Split {
            flex-direction: row;
            justify-content: space-between;
            column-gap: 5%;

            .CheckoutBodySectionInputField {
              &.first {
                width: 20%;
              }

              &.second {
                width: 80%;
              }
            }
          }
        }
      }

      .PaymentDetails,
      .PlanDetails {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 25px;
        box-sizing: border-box;
        background-color: white;
        height: 80%;
        width: 90%;
        border-radius: 10px;
        color: black;

        &Title {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 28px;
          font-weight: bolder;
          height: 10%;
        }

        &Price {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: column;
          height: 10%;

          :first-child {
            font-size: 25px;
            font-weight: bolder;
          }

          :nth-child(2) {
            font-size: 12px;
            color: gray;
          }
        }

        &Benefit {
          display: flex;
          align-items: center;
          height: 50px;
          justify-content: space-between;

          &Icon {
            width: 10%;
          }

          &Text {
            width: 90%;
          }
        }
      }

      .PaymentDetails {
        &Title {
          justify-content: flex-start;
          height: 10%;
        }

        &Body {
          height: 80%;
        }

        &Footer {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 10%;
          font-weight: bold;
          font-size: 25px;
        }

        &Input {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 90%;

          &Field {
            display: flex;
            flex-direction: column;
            row-gap: 8px;

            input {
              border: 3px solid lightgray;
              border-radius: 5px;
              height: 30px;
              padding: 10px;

              &:focus {
                outline: none;
              }
            }

            &.Split {
              flex-direction: row;
              column-gap: 14px;
            }

            &.SplitItem {
              width: calc(50% - 7px);
            }
          }
        }
      }
    }
  }

  &Footer {
    display: flex;
    box-sizing: border-box;
    justify-content: space-around;
    padding: 15px;
    height: 10%;
    width: 100%;

    //&Button {
    //  border: none;
    //  width: 40%;
    //  color: white;
    //  border-radius: 5px;
    //  background-color: #0B3F3C;
    //  cursor: pointer;
    //
    //  &.return {
    //    background-color: #5C5B5B;
    //    cursor: pointer;
    //  }
    //}
  }
}

.ErrorsContainer {
  width: 90%;

  .Error {
    display: flex;
    width: 100%;
    height: 50px;
    justify-content: space-between;

    &Alert {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 10%;
    }

    &Text {
      display: flex;
      padding-left: 5px;
      align-items: center;
      width: 90%;
    }
  }
}
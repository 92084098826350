table {
  white-space: nowrap;
  border-collapse: collapse;
  border-radius: 10px;
  width: 100%;
}

tbody {
  height: 80%;
}

.TableContainer {
  width: 100%;
  overflow-x: auto;
}

.Table {

  &Wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
  }

  &Content {
    width: 100%;
    height: 94%;
    overflow: scroll;
  }

  &Header {
    position: sticky;
    top: 0;
    z-index: 1;

    &.FirstRowSticky {
      th:first-child {
        position: sticky;
        left: 0;
        z-index: 2;
        background-color: #3f0b0e;
      }
    }

    th {
      text-align: left;
      white-space: nowrap;
    }
    th:first-child {
      border-radius: 10px 0 0 10px;
    }
    th:last-child {
      border-radius: 0 10px 10px 0;
    }

    td {
      white-space: nowrap;
    }
    background-color: #3f0b0e;
    color: white;
    border-radius: 10px;
  }

  &Item {
    border-top: 1px solid #ddd;
    border-radius: 10px;
    height: 70px;
    width: 100%;
    font-size: 12px;

    &.FirstRowSticky &.Selected {
      td:first-child {
        position: sticky;
        left: 0;
        background-color: #ddd;
      }
    }

    &.FirstRowSticky {
      td:first-child {
        position: sticky;
        left: 0;
        background-color: #f8f8f8;
      }
    }

    &.Selected {
      background-color: #ddd;
    }

    &.compact {
      height: 20px;
    }

    .ActionsContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: 10px;
      .ActionIcon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25px;
        height: 25px;
        padding: 5px;
        border-radius: 10px;
        background-color: #3f0b0e;
        cursor: pointer;
      }
    }
  }
}

.TableControls {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 10px;
  height: 6%;

  &.marginTop {
    margin-top: 15px;
  }

  #itemsPerPageSelector {
    margin-right: 10px;
  }

  .ItemsPerPageSelector {
    width: 50px;
    height: 30px;
  }
}

.Pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;

  button {
    background-color: #3f0b0e;
    color: white;
    border: none;
    padding: 5px 10px;
    margin: 0 2px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 12px;
    height: 30px;

    &:disabled {
      background-color: #ccc;
      cursor: not-allowed;
    }

    &.active {
      background-color: #555;
    }
  }

  .PageNumber {
    display: inline-block;
    width: 30px;
    text-align: center;
    line-height: 20px;
  }
}

.modal {
  display: none;
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);

  &.show {
    display: block;
  }

  .modal-content {
    background-color: #fefefe;
    margin: 5% auto;
    border: 1px solid #888;
    width: 80%;
    max-width: 500px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    border-radius: 10px;

    .modal-header {
      background-color: #4b1e1e;
      color: white;
      padding: 10px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;

      h2 {
        margin: 0;
        font-size: 1.5rem;
      }

      .close-button {
        background: none;
        border: none;
        font-size: 1.5rem;
        color: white;
        cursor: pointer;
      }
    }

    .modal-body {
      padding: 20px;
      text-align: center;

      p {
        margin: 10px 0;
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        text-align: left;

        li {
          margin-left: 20px;
        }
      }
    }

    .modal-footer {
      display: flex;
      justify-content: space-between;
      padding: 10px;
      border-top: 1px solid #ccc;

      .cancel-button,
      .confirm-button {
        padding: 10px 10px;
        cursor: pointer;
        border-radius: 5px;
        border: none;
        font-size: 12px;
        width: 40%;
      }

      .cancel-button {
        background-color: #4b1e1e;
        color: white;
      }

      .confirm-button {
        background-color: #1e4b4b;
        color: white;
      }
    }
  }
}

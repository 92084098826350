.FormErrorMessage {
  display: flex;
  gap: 5px;
  align-items: center;
  padding: 5px;
  margin: 5px 0;
  width: auto;
  border-radius: 10px;
  color: red;

  &.active {
    background-color: #f0f0f0;
    max-height: 25px;
  }
}